import React from "react";
import CurrentTime from "../../components/CurrentTime/CurrentTime";
import HeadingGames from "../../components/HeadingGames/HeadingGames";
import GameInfo from "../../components/GameInfo/GameInfo";
import ResultTable from "../../components/ResultTable/ResultTable";
import Contact from "../../components/Contact/Contact";
import ResultByMonth from "../../components/ResultByMonth/ResultByMonth";
import LuckySattaArticle from "../../components/LuckySattaArticle/LuckySattaArticle";
import Footer from "../../components/Footer/Footer";
import Layout from "../../components/Layout/Layout";

function HomePage() {
  return (
    <Layout title={"LUCKY SATTA | DAILY LUCKY SATTA RESULT | DELHI FARIDABAD GHAZIABAD GALI"} description={"Get Daily Lucky Satta results like Gali, Desawar, Faridabad, and Ghaziabad. Check your Lucky Satta, Satta King, and Delhi Bazar Satta outcomes superfast. Monthly results for Desawar, and more on Lucky-Satta.co for quick, reliable updates."}>
      <CurrentTime />
      <HeadingGames />
      <GameInfo />
      <ResultTable />
      <Contact />
      <ResultByMonth />
      <LuckySattaArticle />
      <Footer />
    </Layout>
  );
}

export default HomePage;
