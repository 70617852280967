import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../Layout/Layout";

const DNAME = process.env.REACT_APP_DNAME;

function GameYearlyChart() {
  const { "game-chart": gameSlug } = useParams();
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [results, setResults] = useState([]);
  const navigate=useNavigate()
  // Populate years dropdown from 2020 to the current year
  const years = Array.from(
    { length: currentYear - 2020 + 1 },
    (_, i) => 2020 + i
  );

  useEffect(() => {
    const startDate = `${selectedYear}-01-01T00:00:00.000Z`;
    const endDate = `${selectedYear}-12-31T23:59:59.999Z`;

    const fetchResults = async () => {
      try {
        const deslugify = (slug) =>
          slug
            .replace(/-/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
        const name = deslugify(gameSlug);
        const response = await axios.get(
          `${DNAME}/game-results/${name}/${startDate}/${endDate}`
        );
        setResults(response.data);
      } catch (error) {
        console.error("Error fetching results:", error);
      }
    };

    fetchResults();
  }, [gameSlug, selectedYear]);

  // Create an array of 31 days and an array of 12 months to structure the table
  const days = Array.from({ length: 31 }, (_, i) => i + 1); // Days 1 to 31
  const months = Array.from({ length: 12 }, (_, i) =>
    new Date(0, i).toLocaleString("default", { month: "long" })
  );

  // Organize results into a map by month and day
  const resultsByMonthAndDay = Array.from({ length: 12 }, () =>
    Array(31).fill("-")
  );

  results.forEach((result) => {
    const date = new Date(result.date);
    const monthIndex = date.getMonth();
    const dayIndex = date.getDate() - 1;
    resultsByMonthAndDay[monthIndex][dayIndex] = result.result;
  });

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  };


  const handleMonthResult = (month) =>{
    const currentDate = new Date();
    const montht = month.toLowerCase();
    const year = currentDate.getFullYear();
    navigate(`/${gameSlug}/${slugify(montht)}-${year}`);
  }

  return (
    <Layout>
    <div className="">
      <h2 className="h-20 heading font-bold text-xl sm:text-2xl flex justify-center items-center">
        {gameSlug.toUpperCase()} Results for {selectedYear}
      </h2>

      <div className="h-20 heading font-bold text-xl sm:text-2xl flex justify-center items-center">
        <div>
          {" "}
          <label
            htmlFor="year-select"
            className="block font-semibold text-center mb-2"
          >
            Select Year:
          </label>
        </div>
        <div className="flex justify-center items-center">
          <select
            id="year-select"
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            className="text-black p-2 rounded text-xl sm:text-2xl block mx-auto mb-4"
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="border-collapse sm:w-[100%] sm:mx-auto table-auto">
          <thead>
            <tr>
              <th className="resulttableIstcol border border-slate-400 p-2 w-[10%]">
                Date
              </th>
              {months.map((month, index) => (
                <th
                key={index}
                className="border border-slate-400 p-2 resulttableIstcol cursor-pointer"
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent anchor default action
                    handleMonthResult(month); // Call your function
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" hover:underline"
                >
                  {month}
                </a>
              </th>
              
              ))}
            </tr>
          </thead>
          <tbody>
            {days.map((day) => (
              <tr key={day}>
                <td className="border border-slate-400 p-2 text-center resulttableIstcol w-auto">
                  {day}
                </td>
                {resultsByMonthAndDay.map((monthResults, monthIndex) => (
                  <td
                    key={monthIndex}
                    className="border border-slate-400 p-2 text-center"
                  >
                    {monthResults[day - 1]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </Layout>
  );
}

export default GameYearlyChart;
